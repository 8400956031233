<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Check-in: Service Area Selection Options</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ selectedTermLabel || 'Select Term' }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="({ term, label }, index) in terms" :key="'term-' + index" @click="selectTerm({ term, label })">
              <v-list-item-title>{{ label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ selectedArea || 'Select Area' }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="selectedTerm == null || selectedTerm === ''">
              <v-list-item-content>
                <v-list-item-title>Select Term</v-list-item-title>
                <v-list-item-subtitle>You must select the term first</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <template v-else>
              <v-list-item v-for="({ area, _id }, index) in areas" :key="'area-' + index" @click="selectArea(_id)">
                <v-list-item-title>{{ area }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-dialog v-model="newAreaDialog" width="400" persistent>
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>Add Area</v-list-item-title>
                      <v-list-item-subtitle>Will create a new area scanning options for {{ selectedTermLabel }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-card>
                  <v-toolbar>
                    <v-toolbar-title>New Area</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="newAreaDialog = false">
                      <v-icon>fal fa-times</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-combobox v-model="newAreaName" :items="previousAreas" label="Name of area">
                      <template v-slot:append-outer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                          </template>
                          <span>Enter the name of the area (i.e. Math Center, Writing Center, etc). The list will populate with the areas that you can see for other terms but are not set up for the selected term. You can ignore this list and enter a name, but if you want to copy the options from a previous term you will need to select the option from the list.</span>
                        </v-tooltip>
                      </template>
                    </v-combobox>
                    <v-select v-if="isCopiedArea" v-model="newAreaCopyTerm" :items="previousTerms" label="Copy options from term"></v-select>
                    <v-select v-model="newAreaRoles" :items="roles" label="Roles who can access it" multiple></v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="success" text @click="saveNewArea">Create Area</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="newAreaDialog = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <p>This page allows administrators to set up the selection options for their areas. This page will list the areas that the logged-in user can see, and then they can select an area to view or edit the selection options. This will all be based on the selected term code above, since these area options are set up per term.</p>
      </v-card-text>
      <v-list v-if="options.length > 0" style="width:400px">
        <template v-for="({ text, classList, subOptions }, index) in options">
          <v-list-item :key="'option-' + index">
            <v-list-item-title>{{ classList ? '** Will include a list of the student\'s classes here **' : text }}</v-list-item-title>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="{ on }" small icon @click="openSubOptionDialog(index)">
                    <v-icon>fal fa-plus-circle</v-icon>
                  </v-btn>
                </template>
                <span>Add Second-tier option (i.e. a second level of choice after they choose this option)</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <template v-if="subOptions && subOptions.length > 0">
            <v-list-item v-for="({ text, classList }, subIndex) in subOptions" :key="'sub-option-' + index + '-' + subIndex">
              <v-list-item-avatar></v-list-item-avatar>
              <v-list-item-title>{{ classList ? '** Will include a list of the student\'s classes here **' : text }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>
      <v-card-actions v-if="selectedAreaId !== ''">
        <v-dialog v-model="addOptionDialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text color="success">Add Option</v-btn>
          </template>
          <v-card>
            <v-card-title>Add option to the main select list</v-card-title>
            <v-card-text>
              <v-checkbox v-model="newOptionClassList" label="Make this a class selection"></v-checkbox>
              <v-text-field v-if="!newOptionClassList" v-model="newOption" label="Option Text"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="addOptionDialog = false">Cancel</v-btn>
              <v-btn text color="success" @click="addOption(index)">Add Option</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="subOptionDialog" width="400">
      <v-card>
        <v-card-title>Add Second-Tier Option</v-card-title>
        <v-card-text>
          <p>Will display after selecting {{ optionClassList ? 'a class' : '"' + optionText + '"' }}</p>
          <v-checkbox v-if="!optionClassList" v-model="newSubOptionClassList" label="Make this a class selection"></v-checkbox>
          <v-text-field v-if="!newSubOptionClassList" v-model="newSubOption" label="Sub Option Text"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="subOptionDialog = false">Cancel</v-btn>
          <v-btn text color="success" @click="addSubOption()">Add Sub-Option</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const terms = ref([
      { term: '202060', label: 'Fall 2020' },
      { term: '202105', label: 'Spring 2021' },
      { term: '202160', label: 'Fall 2021' },
      { term: '202205', label: 'Spring 2022' },
      { term: '202260', label: 'Fall 2022' }
    ])
    const selectedTerm = computed(() => root.$store.state.selectedTerm)
    const selectedTermLabel = computed(() => root.$store.state.selectedTermLabel)
    function selectTerm (obj) {
      root.$store.commit('setTerm', obj)
    }
    const areas = ref([])
    const selectedAreaId = ref('')
    const selectedArea = ref('')
    const options = ref([])
    function selectArea (id) {
      service.get(id).then((data) => {
        selectedAreaId.value = data._id
        selectedArea.value = data.area
        options.value = data.options
      })
    }

    const service = root.$feathers.service('checkin/department')

    onMounted(() => {
      if (selectedTerm.value !== '') {
        loadServiceAreas()
      }
    })
    watch(selectedTerm, () => loadServiceAreas())

    function loadServiceAreas () {
      service.find({ query: { term: selectedTerm.value, $select: ['area', '_id'] } }).then(({ data }) => {
        areas.value = data
      })
    }

    const newAreaDialog = ref(false)
    const newAreaName = ref('')
    const newAreaCopyTerm = ref('')
    const newAreaRoles = ref([])
    const previousAreas = ref([])
    const previousTerms = computed(() => {
      let arr = []
      for (let i = 0; i < terms.value.length; i++) {
        if (terms[i].term === selectedTerm.value) break
        arr.push(terms[i])
      }
      return arr
    })
    const isCopiedArea = ref(false)

    function saveNewArea () {
      service.create({
        area: newAreaName.value,
        term: selectedTerm.value,
        createdBy: user.value.username,
        roles: newAreaRoles.value
      }).then((data) => {
        console.log(data)
      })
    }

    const addOptionDialog = ref(false)
    const newOptionClassList = ref(false)
    const newOption = ref('')
    function addOption () {
      let obj = { subOptions: [] }
      if (newOptionClassList.value) {
        obj.classList = true
      } else {
        obj.text = newOption.value
      }
      options.value.push(obj)
      newOption.value = ''
      newOptionClassList.value = false
      addOptionDialog.value = false
    }

    const subOptionDialog = ref(false)
    const subOptionIndex = ref(-1)
    const optionClassList = ref(false)
    const optionText = ref('')
    const newSubOptionClassList = ref(false)
    const newSubOption = ref('')
    function openSubOptionDialog (index) {
      subOptionIndex.value = index
      optionClassList.value = options.value[index].classList
      optionText.value = options.value[index].text
      newSubOptionClassList.value = false
      newSubOption.value = ''
      subOptionDialog.value = true
    }
    function addSubOption () {
      let temp = Object.assign({}, options.value[subOptionIndex.value])
      let obj = { }
      if (newSubOptionClassList.value) {
        obj.classList = true
      } else {
        obj.text = newSubOption.value
      }
      temp.subOptions.push(obj)
      options.value.splice(subOptionIndex.value, 1, obj)
      subOptionDialog.value = false
    }

    return {
      user,
      roles,
      terms,
      selectedTerm,
      selectedTermLabel,
      selectTerm,
      areas,
      selectedAreaId,
      selectedArea,
      options,
      selectArea,
      newAreaDialog,
      newAreaName,
      newAreaCopyTerm,
      newAreaRoles,
      previousAreas,
      previousTerms,
      isCopiedArea,
      saveNewArea,
      addOptionDialog,
      newOptionClassList,
      newOption,
      addOption,
      subOptionDialog,
      subOptionIndex,
      optionClassList,
      optionText,
      newSubOptionClassList,
      newSubOption,
      openSubOptionDialog,
      addSubOption
    }
  }
}
</script>
